.c-select{
    border-radius: 0.25rem;
    height: 36px;
}

table, .datatable-pagination{
    background-color: $tgBackgroundColor !important;
    color: $tgTextColor !important;
}

.navbar {
    background-color: $tgBackgroundColor !important;
}

.datepicker-toggle-button, .form-label, input, .select-arrow, .commit-log {
    color: $tgTextColor !important;
}

.fake-link {
    color: $tgLinkColor !important;
    cursor: pointer;
}

.break-spaces {
    white-space: break-spaces;
}

.pre-wrap {
    white-space: pre-wrap;
}

.break-word {
    word-break: break-word;
}

.pre-line {
    white-space: pre-line;
}

.datatable-table {
    th {
        font-weight: bold !important;
        padding: 2px 2px 2px 20px !important;
        white-space: pre-wrap !important;
    }

    td {
        padding: 2px !important;
    }

}

.tg-bg {
    background-color: var(--tg-theme-bg-color, #fff);
    color: var(--tg-theme-text-color, #000);
}

.tg-input, .tg-input:focus {
    background-color: $tgBackgroundColor !important;
    color: $tgTextColor !important;
}

.input-no-outline, .input-no-outline:focus, .input-no-outline:active {
    outline: none!important;
    border: none!important;
    border-color: white;
}

.tg-secondary-bg, .tg-secondary-bg:focus {
    background-color: var(--tg-theme-secondary-bg-color, #fff)!important;
    color: var(--tg-theme-text-color, #000);
}

.tg-link, .tg-link:hover, .tg-link:active, .tg-link:focus {
    color: var(--tg-theme-link-color, #000)!important;
    text-decoration: underline;
}

.no-underline {
    text-decoration: none!important;
}

.tg-button {
    background-color: var(--tg-theme-button-color, #000)!important;
    color: var(--tg-theme-button-text-color, #fff)!important;

    &:disabled {
        background-color: var(--tg-theme-disabled-button-color, red)!important;
    }

    &:hover, &:active {
        background-color: var(--tg-theme-secondary-button-color)!important;
    }
}

.tg-button-color {
    color: var(--tg-theme-button-color, #000);
}

.tg-button-bg {
    background-color: var(--tg-theme-button-color, #000);
    color: var(--tg-theme-button-text-color, #fff)
}

.tg-link-color {
    color: var(--tg-theme-link-color, #000);
}

.tg-hint-color {
    color: var(--tg-theme-hint-color, #000);
}

.tg-text {
    color: var(--tg-theme-text-color, #000)
}

.tg-breadcrumb {
    > .breadcrumb-item::before {
        color: var(--tg-theme-hint-color, #000)!important;
    }
}

.tg-radio {
    background-color: var(--tg-theme-bg-color, #fff)!important;
    accent-color: var(--tg-theme-button-color, #000)!important;
    border-color: var(--tg-theme-secondary-button-color)!important;

    &:checked:after {
        background-color: var(--tg-theme-secondary-button-color)!important;
    }

    &:not(:checked):after {
        background-color: var(--tg-theme-bg-color, #fff)!important;
    }

}

.tg-modal {
    .modal-dialog {
        background-color: var(--tg-theme-bg-color, #fff)!important;

        .modal-header {
            background-color: var(--tg-theme-bg-color, #fff)!important;
            color: var(--tg-theme-text-color, #000)!important;
            border-bottom: 1px solid var(--tg-theme-hint-color, grey);
        }

        .modal-body {
            background-color: var(--tg-theme-bg-color, #fff)!important;
            color: var(--tg-theme-text-color, #000)!important;

            input {
                background-color: var(--tg-theme-bg-color, #fff)!important;
            }
        }

        .modal-footer {
            background-color: var(--tg-theme-bg-color, #fff)!important;
            color: var(--tg-theme-text-color, #000)!important;
            border-top: 1px solid var(--tg-theme-hint-color, grey);
        }
    }
}

.tg-table-editor-wrapper {

    .tg-table-editor {
        background-color: var(--tg-theme-bg-color, white);
        color: var(--tg-theme-text-color, red);

        table {

            thead {
                tr {
                    border-bottom: 1px solid var(--tg-theme-hint-color, grey)!important;
                }
            }

            tbody  {
                tr:last-child {
                    border-bottom: 1px solid var(--tg-theme-hint-color, grey)!important;
                }
            }

            border-bottom: 1px solid var(--tg-theme-hint-color, grey)!important;
            color: var(--tg-theme-text-color, red);

            td {
                color: var(--tg-theme-text-color, red);
            }

            button {
                color: var(--tg-theme-text-color, red)!important;
            }
        }
        .select-arrow {
            color: var(--tg-theme-text-color, red);
        }

        .table-editor__pagination {
            border-top: none;
            margin: 0;
            justify-content: end;

            .table-editor__select-wrapper {
                text-align: center;
                width: 50%;
                padding: 0;

                .table-editor__select-text{
                    padding: 0;
                    margin: 0 .5rem 0 0;
                }
            }

            .table-editor__pagination-nav {
                text-align: center;
                margin-left: .5rem;
            }

            .table-editor__pagination-buttons {
                text-align: end;
                margin-left: .5rem;
            }
        }

    }

    > div:first-child {
        .btn {
            background-color: var(--tg-theme-button-color, #000)!important;
            color: var(--tg-theme-button-text-color, #fff)!important;

            &:disabled {
                background-color: var(--tg-theme-disabled-button-color)!important;
            }

            &:hover, &:active {
                background-color: var(--tg-theme-secondary-button-color)!important;
            }
        }
    }

    .form-label {
        color: var(--tg-theme-text-color, red)!important;
    }

    .form-control {
        color: var(--tg-theme-text-color, red)!important;
    }

    .form-control:disabled {
        background-color: var(--tg-theme-secondary-bg-color, grey);
    }
}

.tg-switch {
    background-color: var(--tg-theme-hint-color, grey)!important;

    &::after {
        background-color: white!important;
    }

    &:checked {
        background-color: var(--tg-theme-button-color, #000)!important;
        outline: none;
        &::after {
            background-color: var(--tg-theme-button-color, #000)!important;
        }
    }
}

.rfs-1 {
    font-size: 1vw
}

.rfs-2 {
    font-size: 2vw
}

.rfs-3 {
    font-size: 3vw
}

.rfs-3-5 {
    font-size: 3.5vw
}

.rfs-4 {
    font-size: 4vw
}

.rfs-5 {
    font-size: 5vw
}

.justify-self-start {
    justify-self: start;
}

.justify-self-end {
    justify-self: end;
}

.vertical-align-center {
    vertical-align: center;
}

.tg-card {
  background-color: var(--tg-theme-bg-color, #fff)!important;
  color: var(--tg-theme-text-color, #000)!important;

  > .catalog-card-media {
    img, video {
      max-width: 100%;
    }
  }

  .card-footer {
    border-top: .0001px ridge var(tg-theme-text-color, grey);
  }
}

.tg-checkbox {
    background-color: var(--tg-theme-bg-color, #fff)!important;
    border-color: var(--tg-theme-text-color, grey)!important;

    &:checked {
        background-color: var(--tg-theme-button-color, #000)!important;
        border-color: var(--tg-theme-button-color, #000)!important;
    }
}

.tg-popconfirm {
    border-color: var(--tg-theme-secondary-bg-color, #fff)!important;
    .popconfirm {
        background-color: var(--tg-theme-bg-color, #fff)!important;
        color: var(--tg-theme-text-color, #000)!important;
        border-color: var(--tg-theme-secondary-bg-color, #000)!important;
    }
}


.tg-react-select {
    color: black!important;

    input {
        color: black!important;
    }
}
