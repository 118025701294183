:root {
    --tg-background-color: #ffffff;
    --tg-button-color: #40a7e3;
    --tg-button-text-color: #ffffff;
    --tg-hint-color: #999999;
    --tg-link-color: #168acd;
    --tg-secondary-bg-color: #f1f1f1;
    --tg-text-color: #000000;
}

$tgBackgroundColor: var(--tg-background-color);
$tgButtonColor: var(--tg-button-color);
$tgButtonTextColor: var(--tg-button-text-color);
$tgHintColor: var(--tg-hint-color);
$tgLinkColor: var(--tg-link-color);
$tgSecondaryBackgroundColor: var(--tg-secondary-bg-color);
$tgTextColor: var(--tg-text-color);